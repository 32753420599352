@import url('https://fonts.cdnfonts.com/css/baloo-tamma');

body {
  background: linear-gradient(to bottom , #0D9488, #3ac4b8 ) ;
  margin: 0;
}
.parent-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh; 
}
.login-container {
  padding: 20px;
  background-color: transparent;
  border-radius: 76px;
  box-shadow: 0 0 0 7px rgba(255, 255, 255, 0.5), 0 0 0 4px rgba(255, 255, 255, 0.2);
  animation: electric 1s infinite;
  max-width: 800px;
  width: 100%;
} 
  @keyframes electric {
	0% {
	  box-shadow: 0 0 0 7px rgba(255, 255, 255, 0.817), 0 0 0 7px rgba(255, 255, 255, 0.2);
	}
	50% {
	  box-shadow: 0 0 0 7px rgba(0, 0, 0, 0.871), 0 0 0 7px rgba(255, 255, 255, 0.5);
	}
	100% {
	  box-shadow: 0 0 0 7px rgba(255, 255, 255, 0.847), 0 0 0 7px rgba(255, 255, 255, 0.2);
	}
  }
.header-txt {
  font-family: 'Gagalin', sans-serif;
  font-size: 4em;
  text-align: center;
  color: white;
}
.logo-header{
  text-align: center;
}
.logo{
  width: 185px;
  height: 185px;
}
form {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 40px;
  }
input {
    padding: 10px;
    margin: 5px;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-family: 'Baloo Tamma', sans-serif;
    width: 200px;
  }
  .btn-signin {
    display: flex;
    justify-content: center; 
    align-items: center; 
    margin-top: 30px;
  }

  .btn-59,
.btn-59 *,
.btn-59 :after,
.btn-59 :before,
.btn-59:after,
.btn-59:before {
  border: 0 solid;
  box-sizing: border-box;
}

.btn-59 {
  -webkit-tap-highlight-color: transparent;
  -webkit-appearance: button;
  background-color: #000;
  background-image: none;
  color: #fff;
  cursor: pointer;
  font-size: 100%;
  line-height: 1.5;
  margin: 0;
  -webkit-mask-image: -webkit-radial-gradient(#000, #fff);
  padding: 0;
}

.btn-59:disabled {
  cursor: default;
}

.btn-59:-moz-focusring {
  outline: auto;
}

.btn-59 svg {
  display: block;
  vertical-align: middle;
}

.btn-59 [hidden] {
  display: none;
}

.btn-59 {
  background: none;
  box-sizing: border-box;
  font-size: 1em;
    color: rgb(63, 62, 62);
  display: block;
  font-weight: 900;
  -webkit-mask-image: none;
  padding: 1.5rem 4rem;
  position: relative;
  text-transform: uppercase;
}

.btn-59 span {
  background-color: #fec89a;
  border: 1px solid;
  border-radius: 999px;
  font-family: 'Baloo Tamma', sans-serif;
  display: grid;
  inset: 0;
  place-items: center;
  pointer-events: none;
  position: absolute;
  -webkit-animation: tada 0.8s infinite; 
  animation: tada 0.8s infinite;
}

@-webkit-keyframes tada {
  0% {
    transform: scaleX(1);
  }
  10%,
  20% {
    transform: scale3d(0.9, 0.9, 0.9) rotate(-3deg);
  }
  30%,
  50%,
  70%,
  90% {
    transform: scale3d(1.1, 1.1, 1.1) rotate(3deg);
  }
  40%,
  60%,
  80% {
    transform: scale3d(1.1, 1.1, 1.1) rotate(-3deg);
  }
  to {
    transform: scaleX(1);
  }
}

@keyframes tada {
  0% {
    transform: scaleX(1);
  }
  10%,
  20% {
    transform: scale3d(0.9, 0.9, 0.9) rotate(-3deg);
  }
  30%,
  50%,
  70%,
  90% {
    transform: scale3d(1.1, 1.1, 1.1) rotate(3deg);
  }
  40%,
  60%,
  80% {
    transform: scale3d(1.1, 1.1, 1.1) rotate(-3deg);
  }
  to {
    transform: scaleX(1);
  }
}

  .footer {
    margin-top: 8em;
    text-align: center;
    font-family: 'Baloo Tamma', sans-serif;
  }
  
  .footer p {
    font-size: 1em;
  }
  
  .footer a {
    color: #413f3d;
    text-decoration: none;
  }
  
  .footer a:hover {
    text-decoration: underline;
  }
  .swal2-title {
    font-family:'Baloo Tamma', sans-serif;
  }
  .swal2-popup {
    font-family:'Baloo Tamma', sans-serif;
  }