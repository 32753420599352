@import url('https://fonts.cdnfonts.com/css/gidolinya');
@import url('https://fonts.cdnfonts.com/css/baloo-tamma');

.burger-menu {
  position: fixed;
  top: 20px;
  right: -10px;
  width: 30px;
  height: 30px;
  cursor: pointer;
  z-index: 1000;
}

.burger-menu .bar1, .burger-menu .bar2, .burger-menu .bar3 {
  width: 100%;
  height: 4px;
  background-color: #333;
  margin: 6px 0;
  border-radius: 2em;
  padding: 1px;
  transition: 0.8s;
}

.burger-menu.open .bar1 {
  transform: rotate(-45deg) translate(-5px, 6px);
}

.burger-menu.open .bar2 {
  opacity: 0;
}

.burger-menu.open .bar3 {
  transform: rotate(45deg) translate(-5px, -6px);
}

.menu-content {
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  width: 30%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.9);
  box-shadow: -0 0 5px rgba(0, 0, 0, 0.5);
  z-index: 999;
  transition: 0.4s;
  padding: 50px 20px;
}

.menu-content.show {
  display: block;
}

.menu-icon {
text-align: center;
margin: 3em;
}
.menu-txt {
  font-size: 2.4em;
  margin-bottom: 1.1em;
  text-align: center;
  font-family: 'Baloo Tamma', sans-serif;
}
.icon-tele {
  width: 50px;
  height: 50px;
}
.icon-tele:hover {
  transform: scale(1.3); 
  transition: color 0.3s, transform 0.3s; 
}
.icon-twt {
  width: 50px;
  height: 50px;
  margin-right: 40px;
}
.icon-twt:hover {
  transform: scale(1.3); 
    transition: color 0.3s, transform 0.3s; 
}
.menu-content ul li {
  margin: 20px 0;
}

.menu-content ul li a,
.menu-content ul li{
  font-size: 18px;
  font-family: 'Gidolinya', sans-serif;
  text-decoration: none;
  color: #333;
  background: none;
  border: none;
  cursor: pointer;
}
.btn-container {
  display: flex;
  justify-content: center; 
  align-items: center; 
  height: 40vh;
}
.btn-logout {
  text-align: center;
  margin-top: 20px;
}
/* CSS for the Button */
.btn-90,
.btn-90 *,
.btn-90 :after,
.btn-90 :before,
.btn-90:after,
.btn-90:before {
  border: 0 solid;
  box-sizing: border-box;
}
.btn-90 {
  -webkit-tap-highlight-color: transparent;
  -webkit-appearance: button;
  background-color: #000;
  background-image: none;
  color: #fff;
  cursor: pointer;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont,
    Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif,
    Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  font-size: 100%;
  font-weight: 900;
  line-height: 1.5;
  margin: 0;
  -webkit-mask-image: -webkit-radial-gradient(#000, #fff);
  padding: 0;
}
.btn-90:disabled {
  cursor: default;
}
.btn-90:-moz-focusring {
  outline: auto;
}
.btn-90 svg {
  display: block;
  vertical-align: middle;
}
.btn-90 [hidden] {
  display: none;
}
.btn-90 {
  --size: 60px;
  background: none;
  border-radius: 999px;
  box-sizing: border-box;
  display: block;
  -webkit-mask-image: none;
  padding: 20px 0;
  position: relative;
  text-transform: uppercase;
}
.btn-90 span {
  font-weight: 900;
  mix-blend-mode: difference;
}
.btn-90 .blobs {
  filter: url(#goo);
  inset: 0;
  pointer-events: none;
  position: absolute;
}
.btn-90 .blobs:after,
.btn-90 .blobs:before {
  background: black;
  border-radius: 50%;
  content: "";
  height: var(--size);
  pointer-events: none;
  position: absolute;
  top: calc(50% - var(--size) / 2);
  width: var(--size);
}

/* Keyframes for Continuous Animation */
@keyframes moveBlobsBefore {
  0%, 100% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(-100%);
  }
}

@keyframes moveBlobsAfter {
  0%, 100% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(100%);
  }
}

/* Apply Animation to Blobs */
.btn-90 .blobs:before {
  left: 100%;
  animation: moveBlobsBefore 3s infinite;
}
.btn-90 .blobs:after {
  right: 100%;
  animation: moveBlobsAfter 3s infinite;
}

@media (max-width: 726px) {
  .menu-content {
   width: 50%;
   height: 100%;
  }
  .icon-tele {
    width: 40px;
    height: 40px;
  }
  .icon-twt{
    width: 40px;
    height: 40px;
    margin-right: 7px;
  }
}
