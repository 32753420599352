@import url('https://fonts.cdnfonts.com/css/greek-house-fathouse');
@import url('https://fonts.cdnfonts.com/css/kick-assinger');
@import url('https://fonts.cdnfonts.com/css/baloo-tamma');
@import url('https://fonts.cdnfonts.com/css/gidolinya');
@import url('https://fonts.cdnfonts.com/css/gagalin');

body {
    background: linear-gradient(to bottom , #0D9488, #3ac4b8 ) ;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

/* .dashboard-container {
    padding: 20px;
    background-color: #fadfbb;
    border-radius: 16px;
    box-shadow: 0 0 0 7px white;
    max-width: 800px;
    margin: 20px auto;
} **/

.header {
    font-size: 4.7em;
    font-weight: bold;
    text-align: center;
    margin-bottom: 20px;
    color: #fff;
    font-family: 'Gagalin', sans-serif;
}
.logo-header {
    text-align: center;
    margin-top: 2em;
}
@keyframes scaleEffect {
  0% {
    transform: scale(1.2);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1.2);
  }
}

.img-logo {
  width: 160px;
  height: 160px;
  animation: scaleEffect 2.7s ease-in-out infinite;
}

/* .point, .reff-code, .reff-count {
    margin-bottom: 10px;
    font-size: 1.2em;
    color: #333;
    font-family: 'Baloo Tamma', sans-serif;
} **/

.point {
    margin-top: 3em;
    font-size: 2.4em;
    font-family: 'Gagalin', sans-serif;
    text-align: center;
    font-weight: bold;
    color: white;
}
.referral-link-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 80px;
  }

.div-logout {
    margin-top: 20px;
}

.leaderboard-container {
  width: 100%;
  max-width: 600px;
  top: 200px;
  margin:100px auto 20px auto;
  padding: 20px;
  background-color: transparent;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  font-family: 'Baloo Tamma', sans-serif;
}

.lb-text {
  font-size: 35px;
  margin-bottom: 30px;
  font-family: 'Baloo Tamma', sans-serif;
  text-align: center;
  color: white;
}

.leaderboard-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  border-bottom: 1px solid #fff;
}

.leaderboard-item:last-child {
  border-bottom: none;
}

.leaderboard-rank {
  font-size: 18px;
  font-weight: bold;
  color: white;
}

.leaderboard-user {
  flex-grow: 1;
  margin-left: 10px;
}

.leaderboard-username {
  font-size: 18px;
  font-weight: bold;
  color: white;
}

.leaderboard-points {
  font-size: 14px;
  color: rgba(242, 242, 242, 0.917);
}
.referral-link-input {
  flex: 1;
  padding: 10px;
  font-size: 1em;
  border: 1px solid #fff;
  border-radius: 5px;
  background-color: transparent;
  margin-right: 10px;
}
.btn-41,
.btn-41 *,
.btn-41 :after,
.btn-41 :before,
.btn-41:after,
.btn-41:before {
  border: 0 solid;
  cursor: pointer;
  box-sizing: border-box;
}
.btn-41 {
  -webkit-tap-highlight-color: transparent;
  -webkit-appearance: button;
  background-color: black;
  background-image: none;
  color: rgb(255, 247, 247);
  cursor: pointer;
  font-family: 'Gidolinya', sans-serif;
  font-size: 100%;
  line-height: 1.5;
  margin: 0;
  -webkit-mask-image: -webkit-radial-gradient(#000, #fff);
  padding: 0;
}
.btn-41:disabled {
  cursor: default;
}
.btn-41:-moz-focusring {
  outline: auto;
}
.btn-41 svg {
  display: block;
  vertical-align: middle;
}
.btn-41 [hidden] {
  display: none;
}
.btn-41 {
  border: 2px solid #fff;
  border-radius: 999px;
  box-sizing: border-box;
  display: block;
  font-weight: 900;
  overflow: hidden;
  padding: 0.7rem 1.2rem;
  position: relative;
  text-transform: uppercase;
}
.btn-41 span {
  mix-blend-mode: difference;
  font-family: 'Baloo Tamma', sans-serif;
  font-weight: bold;
  font-size: 15px;
}
.btn-41:before {
  background: #fff;
  border-radius: 999px;
  content: "";
  inset: 0;
  position: absolute;
  transition: transform 0.2s;
}
.btn-41:hover:before {
  transform: scale(0.7);
}
.apply-btn.disabled {
    background-color: #d3d3d3; 
    cursor: not-allowed;
  }
  .input-referral-code-container {
    text-align: center;
    margin-top: 2em;
  }
  .apply-btn {
    padding: 10px 18px;
    font-size: 0.8em;
    border: 1px solid black;
    border-radius: 5px;
    background-color: #02ec97;
    font-family: 'Baloo Tamma', sans-serif;
    color: black;
    cursor: pointer;
  }
.burger-menu {
    position: relative;
    display: inline-block;
  }
  
  .burger-menu-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
  }
  
  .burger-menu-button span {
    display: block;
    width: 25px;
    height: 3px;
    background-color: #333;
    margin: 5px 0;
    transition: 0.4s;
  }
  
  .burger-menu-button.open span:nth-child(1) {
    transform: rotate(-45deg) translate(-5px, 6px);
  }
  
  .burger-menu-button.open span:nth-child(2) {
    opacity: 0;
  }
  
  .burger-menu-button.open span:nth-child(3) {
    transform: rotate(45deg) translate(-5px, -6px);
  }
  
  .burger-menu-content {
    display: none;
    position: absolute;
    right: 0;
    background-color: #fff;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    margin-top: 10px;
    border-radius: 8px;
    overflow: hidden;
    transition: max-height 0.4s ease-out;
  }
  
  .burger-menu-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
  }
  
  .burger-menu-content a:hover {
    background-color: #ddd;
  }
  
  .burger-menu.show .burger-menu-content {
    display: block;
    max-height: 200px;
  }
  
  .burger-menu-content a:last-child {
    border-top: 1px solid #ddd;
  }
  
  @media (max-width: 726px) {
    .leaderboard-container {
      padding: 15px;
      margin:100px auto 20px auto;
      width: 320px;
      font-size: 14px;
    }
  
    .lb-text {
      font-size: 35px;
      margin-bottom: 30px;
      font-weight: bold;
    }
  
    .leaderboard-item {
      padding: 10px 0;
    }
  
    .leaderboard-rank, 
    .leaderboard-username, 
    .leaderboard-points {
      font-size: 16px;
      text-align: left;
    }
  
    .leaderboard-rank {
      order: 3; 
    }
  
    .leaderboard-user {
      margin-left: 0;
      text-align: left;
    }
  
    .leaderboard-username {
      margin-bottom: 5px;
    }
  
    .leaderboard-points {
      font-size: 14px;
      color: rgba(242, 242, 242, 0.917);
    }
  }
  .footer {
  margin-top: 7em;
  font-family: 'Baloo Tamma', sans-serif;
  font-size: 1.2em;
  color: #fff;
  }